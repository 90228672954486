import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Solutions" />

    <header className="header header--basic ">
        <div className="header__content">
            <div className="container">
                <div className="row">
                    <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                        <h1 className="header__title">Solutions</h1>
                        <h3 className="header__caption">SalesTribe offers a range of services to sales people, including getting connected into businesses.</h3>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div className="landing landing--base">
        <div className="landing__item">
            <div className="container">
                <div className="landing__columns row">
                    <div className="col-xs-12 col-sm-6">
                        <div className="landing__image">
                            <img src="../media/svg/landing-552x580_solutions-sales-professionals.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-sm-offset-1">
                        <Link to="/solutions/im-sales-professional/" className="landing__copy">
                            <h2 className="landing__title">Sales People</h2>
                            <p>Are you equipped to face the today&#39;s challenges in B2B sales?</p>
                            <button className="btn btn-link">Learn more</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="landing__item is-odd">
            <div className="container">
                <div className="landing__columns row">
                    <div className="col-xs-12 col-sm-6 col-sm-push-6">
                        <div className="landing__image">
                            <img src="../media/svg/landing-552x580_solutions-startups.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-sm-pull-5">
                        <Link to="/solutions/im-start-business/" className="landing__copy">
                            <h2 className="landing__title">Companies</h2>
                            <p>SalesTribe is your best-in-class partner for digital sales transformation</p>
                            <button className="btn btn-link">Learn more</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <div className="block block--base video" id="info-video">
            <div className="video__wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="video__image" data-video-uri="https://www.youtube.com/embed/x4EtALLCLa8?autoplay=1&amp;modestbranding=0&amp;rel=0&amp;showinfo=0" data-video-target="#info-video .embed-responsive" data-video-parent="#info-video">
                                <div className="video__bgimage" style={{backgroundImage:"url(/media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-1162x655.jpg)"}}></div>
                                <div className="video__video">
                                    <div className="embed-responsive embed-responsive-16by9"></div>
                                </div>
                            </div>
                            <div className="video__icon play-icon">
                                <i className="icon"></i>
                            </div>
                            <div className="video__content">
                                <div className="row">
                                    <div className="video__inner col-md-offset-2 col-md-8">

                                        <h1>The challenges facing sales people</h1>
                                        <div className="video__caption">
                                            <p className="h3">&quot;Join SalesTribe today to help protect and enhance your sales career&quot;</p>
                                            <p>Graham Hawkins - SalesTribe Mentor</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
    <FooterAlternate1 />

  </Layout>
)

export default IndexPage